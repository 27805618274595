<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');


@font-face {
  font-family: "Gramatika-Black";
  src: url("./assets/fonts/Gramatika-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gramatika-Bold";
  src: url("./assets/fonts/Gramatika-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gramatika-Light";
  src: url("./assets/fonts/Gramatika-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gramatika-Medium";
  src: url("./assets/fonts/Gramatika-Medium.ttf");
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #0055b8;
  --color-2: #ffc844;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #a7a7a7;
  --color-6: #d9d9d9;
  --color-7: #ffffff;
  --color-8: #92c0e9;
  --color-9: #3c3c3c;
  --color-10: #d4f0ff;
  --color-11: #edd8ff;
  --color-12: #fffad0;
  --color-13: #d9ffcc;
  --color-14: #f93549;
  --color-15: #b5ff9b;
  --color-16: #6cb6e0;
  --color-17: #fff496;

 
  --opacity: rgba(255, 255, 255, 0.6);


}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}
.dflxc {
  display: flex;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}
.dflxa {
  display: flex;
  align-items: center;
}
.dflxca {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dflxsp {
  display: flex; 
  justify-content: space-between;
}
.porel{
  position: relative;
}
.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}
.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.crs_ptr {
  cursor: pointer;
}

body::-webkit-scrollbar  {
-webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
 /** border-radius: 1.0416666666666665VW;  */
}

body::-webkit-scrollbar-button:increment,
body::-webkit-scrollbar-button  {
  display: none;
}

body::-webkit-scrollbar:horizontal  {
  height: 0.3645833333333333vw;
}

body::-webkit-scrollbar-thumb  {
  background-color: #FFA929;
 /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #FFA929;
}

body::-webkit-scrollbar-track  {
  border-radius: 0.3645833333333333vw;
}


</style>
