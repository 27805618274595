"use strict";



const local ='http://localhost:3324'
const cpanel ='https://sonorasinhambre.com' // cambiar por la url del sitio web.
const cpanel2 = 'https://bahermosillo.org.mx/el-hambre-tiene-muchos-nombres'

exports.url  =  cpanel + '/api/Ncl_q3O/'; 


